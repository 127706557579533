import { export_json_to_excel } from "@/utils/excel/Export2Excel.js";
import printJS from "print-js";
/**
 * @description: 检测字符串
 */
export function checkStr(str, type) {
  // 常用正则验证，注意type大小写
  switch (type) {
    case "phone": // 手机号码
      return /^1[3|4|5|6|7|8|9][0-9]{9}$/.test(str);
    case "tel": // 座机
      return /^(0\d{2,3}-\d{7,8})(-\d{1,4})?$/.test(str);
    case "card": // 身份证
      return /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/.test(str);
    case "pwd": // 密码以字母开头，长度在6~18之间，只能包含字母、数字和下划线
      return /^[a-zA-Z]\w{5,17}$/.test(str);
    case "pwd2": // 密码以大小写字母、数字
      return /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/.test(str);
    case "postal": // 邮政编码
      return /[1-9]\d{5}(?!\d)/.test(str);
    case "QQ": // QQ号
      return /^[1-9][0-9]{4,9}$/.test(str);
    case "email": // 邮箱
      return /^[\w-]+(.[\w-]+)*@[\w-]+(.[\w-]+)+$/.test(str);
    case "money": // 金额(小数点2位)
      return /^\d*(?:.\d{0,2})?$/.test(str);
    case "IP": // IP
      return /((?:(?:25[0-5]|2[0-4]\d|[01]?\d?\d)\.){3}(?:25[0-5]|2[0-4]\d|[01]?\d?\d))/.test(
        str
      );
    case "date": // 日期时间
      return (
        /^(\d{4})-(\d{2})-(\d{2}) (\d{2})(?::\d{2}|:(\d{2}):(\d{2}))$/.test(
          str
        ) || /^(\d{4})-(\d{2})-(\d{2})$/.test(str)
      );
    case "number": // 数字
      return /^[0-9]$/.test(str);
    case "english": // 英文
      return /^[a-zA-Z]+$/.test(str);
    case "name": // 中文-英文
      return /^[\u4e00-\u9fa5a-zA-Z]{1}$/.test(str);
    case "chinese": // 中文
      return /^[\u4E00-\u9FA5]+$/.test(str);
    case "lower": // 小写
      return /^[a-z]+$/.test(str);
    case "upper": // 大写
      return /^[A-Z]+$/.test(str);
    case "HTML": // HTML标记
      return /<("[^"]*"|'[^']*'|[^'">])*>/.test(str);
    case "letterNumber": // 大写
      return /^[A-Za-z0-9]+$/.test(str);
    case "integer": // 整数
      return /^-?[1-9]\d*$/.test(str);
    default:
      return true;
  }
}
/**
 * @description: 处理导出数据
 */
export function exportExcel(title, tableData, propertiesConfig, label, value) {
  let label_ = label || "displayName";
  let value_ = value || "field";
  let header = [];
  let datas = [];
  propertiesConfig.length &&
    propertiesConfig.forEach((el) => {
      header.push(el[label_]);
    });

  tableData.length &&
    tableData.forEach((item) => {
      let arr = [];
      propertiesConfig.forEach((el) => {
        arr.push(item[el[value_]]);
      });

      datas.push(arr);
    });

  export_json_to_excel(header, datas, title);
}
/**
 * @description: 打印
 */
export function print(titile, tableData, propertiesConfig) {
  const style = "@page {margin:0 10mm};  color:pink";
  printJS({
    printable: tableData,
    properties: propertiesConfig,
    type: "json",
    headerStyle: "font-size:2px;font-weight:600;padding:15px 0 10px 0;", //标题设置
    gridHeaderStyle:
      "font-size:6px;font-weight:400;border: 1px solid #ccc;padding:5px;text-align:center;",
    gridStyle:
      "font-size:1px;font-weight:200;border: 1px solid #ccc;padding:3px;text-align:center;",
    style,
    header: titile,
  });
}
/**
 * @description: 判断是否是手机
 */
export function isMobile() {
  // 判断是否为移动端
  let flag = navigator.userAgent.match(
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  );
  return flag;
}
export function isWeiXin() {
  // 判断是否为微信
  let ua = window.navigator.userAgent.toLowerCase();
  if (ua.match(/MicroMessenger/i) == "micromessenger") {
    return true;
  } else {
    return false;
  }
}
export function getQueryString(name) {
  //企业微信授权获取code问题解决办法
  const reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
  const result = window.location.search.substring(1).match(reg);
  if (result != null) {
    return decodeURIComponent(result[2]);
  }
  return null;
}

export function getPayStatusColors() {
  return ["", "#ffba00", "#67C23A", "#C0C4CC", "#FF3333", "#C0C4CC"];
}
export function getPayStatusOptions() {
  return ["", "待支付", "已支付", "已取消", "申请退款中", "已退款"];
}
export function getWorkStatusColors() {
  return [
    "",
    "#ffba00",
    "#ffba00",
    "#FF7F50",
    "#FF6633",
    "#C0C4CC",
    "#C0C4CC",
    "#C0C4CC",
  ];
}
export function getHotelWorkStatusOptions() {
  return ["", "", "待确认", "待使用", "使用中", "已使用", "已过期", "已取消"];
}
export function getTicketStatusOptions() {
  return ["", "", "", "待使用", "使用中", "已使用", "已过期", "已取消"];
}
export function getActiveStatusOptions() {
  return ["", "待生效", "", "待使用", "使用中", "已使用", "已过期", "已取消"];
}
export function getAssocWorkStatusOptions() {
  return ["", "", "待确认", "待使用", "使用中", "已使用", "已过期", "已取消"];
}
export function getAgentWorkStatusColors() {
  return [
    "",
    "#ffba00", //待生效
    "",
    "#FF7F50", //待售卖
    "#FF6633", //售卖中
    "#67C23A", //已售完
    "#C0C4CC", //已过期
    "#C0C4CC", //已取消
    "#C0C4CC",
  ];
}
export function getPassStatusColors() {
  return ["", "#FF3333", "#ffba00", "#67C23A"];
}

/**
 * 
 * 状态样式
 * 
 * **/
export function orderStatusColors(status, type) {
  let pay_color = '';  // 支付状态  1-待交 2-已支付 3-订单取消 4-申请退款中 5-已退款
  let work_color = '';  // 业务状态  1-待生效 2-待确认 3-待使用 4-使用中 5-已使用 6-已过期 7-已取消
  switch (status) {
    case 1:
      pay_color = 'btn-red';
      work_color = 'btn-red';
      break;
    case 2:
      pay_color = 'btn-green';
      work_color = 'btn-red';
      break;
    case 3:
      pay_color = 'btn-grey';
      work_color = 'btn-orange';
      break;
    case 4:
      pay_color = 'btn-grey';
      work_color = 'btn-yellow';
      break;
    case 5:
      pay_color = 'btn-black';
      work_color = 'btn-blue';
      break;
    case 6:
      pay_color = '';
      work_color = 'btn-black';
      break;
    case 7:
      pay_color = '';
      work_color = 'btn-grey';
      break;
  }

  if (type == 'pay') {
    return pay_color;
  } else {
    return work_color;
  }
}
/**
 * 数组去重
 */
export const unique = (array) => {
	var arr = []; //创建新数组
	for (var i = 0; i < array.length; i++) { //遍历当前数组
		if (arr.indexOf(array[i]) === -1) { //如果等于-1，那么也是就是新数组中没有一项和当前数组一样
			arr.push(array[i])
		}
	}
	return arr;
}