const memberChilds = [
  {
    path: "mactivity",
    name: "Mactivity",
    component: () => import("@/views/member/mactivity.vue"),
    children: [
      {
        path: "activity",
        name: "Activity",
        component: () => import("@/views/member/children/activity.vue"),
      },
    ],
  },
  {
    path: "massociation",
    name: "Massociation",
    component: () => import("@/views/member/massociation.vue"),
    children: [
      {
        path: "association",
        name: "Association",
        component: () => import("@/views/member/children/association.vue"),
      },
    ],
  },
  {
    path: "mmanage",
    name: "Mmanage",
    component: () => import("@/views/member/mmanage.vue"),
    children: [
      {
        path: "manage",
        name: "Manage",
        component: () => import("@/views/member/children/manage.vue"),
      },
      // 积分规则
      {
        path: "integralRule",
        name: "MntegralRule",
        component: () => import("@/views/member/children/integralRule.vue"),
      },
    ],
  },
  // 会员服务
  {
    path: "memberServ",
    name: "MemberServ",
    component: () => import("@/views/member/memberServ.vue"),
    children: [
      {
        path: "carmeet",
        name: "Carmeet",
        component: () => import("@/views/member/children/carmeet.vue"),
      },
    ],
  },
  // 服务订单
  {
    path: "morders",
    name: "Morders",
    component: () => import("@/views/member/orders.vue"),
    children: [
      {
        path: "serviceOrders",
        name: "ServiceOrders",
        component: () => import("@/views/member/children/serviceOrders.vue"),
      },
      {
        path: "activityOrders",
        name: "ActivityOrders",
        component: () => import("@/views/member/children/activityOrders.vue"),
      },
    ],
  },
  {
    path: "mar",
    name: "Mar",
    component: () => import("@/views/member/mar.vue"),
    children: [
      {
        path: "ar",
        name: "Ar",
        component: () => import("@/views/member/children/ar.vue"),
      },
    ],
  },
];
export default memberChilds;
